.inscriptionsContainer {
    width: 100%;
    height: auto;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.inscriptionsContainer .inscriptionsBanner {
    width: 100%;
    max-width: 1200px;
    padding: 2rem 2rem;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 0.5rem;
}

.inscriptionsContainer .inscriptionsBanner .text {
    width: 50%;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

.inscriptionsContainer .inscriptionsBanner .text h2 {
    font-size: 2.8rem;
}
.inscriptionsContainer .inscriptionsBanner .text p {
    font-size: 2rem;
}

.inscriptionsContainer .inscriptionsBanner .text .buttons {
    display: flex;
    gap: 2rem;
}

.inscriptionsContainer .inscriptionsBanner .text .buttons a {
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    border: 0.05rem solid #142b4b;
    text-decoration: none;
    border-radius: 0.3rem;
    font-weight: 600;
}
.inscriptionsContainer .inscriptionsBanner .text .buttons .btnSecundario {
    color: #142b4b;
}
.inscriptionsContainer .inscriptionsBanner .text .buttons .btnPrimario {
    color: #fff;
    background-color: #142b4b;
}

.inscriptionsContainer .inscriptionsBanner .img {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.inscriptionsContainer .inscriptionsBanner .img img {
    width: 100%;
    max-width: 450px;
    min-width: 250px;
    object-fit: fill;
    border-radius: 0.5rem;
}

@media screen and (max-width: 860px) {
    .inscriptionsContainer .inscriptionsBanner {
        flex-direction: column-reverse;
        gap: 2rem;
    }

    .inscriptionsContainer .inscriptionsBanner .text {
        width: 100%;
        align-items: center;
        text-align: center;
    }
    .inscriptionsContainer .inscriptionsBanner .img {
        width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 425px) {
    .inscriptionsContainer .inscriptionsBanner .text {
        width: 100%;
        align-items: center;
        text-align: center;
        font-size: 1.8rem;
    }

    .inscriptionsContainer .inscriptionsBanner .text .buttons {
        flex-direction: column-reverse;
        width: 100%;
        gap: 1rem;
    }
    .inscriptionsContainer .inscriptionsBanner .text .buttons a {
        width: 100%;
        font-size: 1.1rem;
    }
}
@media screen and (max-width: 374px) {
    .inscriptionsContainer .inscriptionsBanner .text h2 {
        font-size: 2rem;
    }
    .inscriptionsContainer .inscriptionsBanner .text p {
        font-size: 1.5rem;
    }

    .inscriptionsContainer .inscriptionsBanner .text .buttons {
        flex-direction: column-reverse;
        width: 100%;
        gap: 1rem;
    }
    .inscriptionsContainer .inscriptionsBanner .text .buttons a {
        width: 100%;
        font-size: 1.1rem;
    }
}
